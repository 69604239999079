.composer-object-toolbar {
    position: relative;
}

.object-toolbar__tabs-section {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
}

.object-toolbar__main-section {
    position: relative;
    z-index: 1;
    background-color: white;
    border-radius: 2px;
    display: flex;
    @extend %box-shadow-far;
}

.object-toolbar__tabs {
    @include clear-list-style;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    margin: auto;
    background-color: $primary-color;
    @extend %box-shadow-far;
}

.object-toolbar__tab {
    flex: 1;
}

.object-toolbar__tab-button {
    @include clear-button-style;
    @include clear-outline;
    padding: 14px 0;
    background-color: $blue;
    color: white;
    font-size: 16px;
    width: 100%;
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover {
        background-color: lighten($blue, 5%);
    }

    .object-toolbar__tab--active & {
        cursor: default;

        &, &:hover {
            background-color: $primary-color;
        }
    }    
}

.object-toolbar__tab + .object-toolbar__tab {
    margin-left: 1px;
}

.object-toolbar__menu-view {
    display: flex;
}

.object-toolbar__menu-item {
    position: relative;
}


.object-toolbar__menu-button {
    @include clear-button-style;
    @include clear-outline;
    width: 80px;
    padding: 15px 5px 10px;
    background-color: white;
    transition: background-color 0.2s;

    &:hover {
        background-color: mix($primary-color, $white, 10%)
    }
}

.object-toolbar__menu-item + .object-toolbar__menu-item {
    margin-left: 5px;
}

.object-toolbar__menu-button-icon {
    display: block;
    font-size: 26px;
    color: $primary-color;
    margin-bottom: 5px;
}

.object-toolbar__menu-item-popover {
    position: absolute;
    bottom: 100%;
    left: 50%;
}

.object-toolbar__info-popover {
    display: none;    
}

.object-toolbar__slider,
.object-toolbar__color-select {
    width: 500px;
    position: relative;
    z-index: 1;
}

.object-toolbar__slider {
    padding: 30px 0 29px;
}

.object-toolbar__color-select {
    padding: 19px;  
}

.object-toolbar__back-button {
    height: 80px;
    width: 80px;
    font-size: 34px;
    line-height: 1;
    color: $primary-color;
    border-right: 1px solid $grey;
    transition: color 0.15s, background-color 0.15s;
    @include clear-button-style;

    span {
        position: relative;
        right: 1px;
    }

    &:hover {
        background-color: $primary-color;
        color: $white;
    }
}
