.composer__header {
    width: 900px;
    display: table;
    margin: 30px 0;
    position: relative;

    .popover {
        right: 0;
        top: 50px;
        transform: none !important;

        .popover__checkbox-wrapper {
            display: none;
        }
    }
}

.composer-header__row {
    display: table-row;
    width: 100%;
    height: 50px;

    > div {
        vertical-align: middle;
        display: table-cell;
    }
}

.composer-header__left {
    width: 25%;
}

.composer-header__right {
    width: 29%;
    text-align: right;
}

.composer-header__center {
    width: 17%;
    text-align: center;
}

.composer-header__hidden-input {
    @include hide-input;
}
