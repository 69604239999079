.composer-scene-toolbar {
    height: 100%;
    @include clear-list-style;
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 1;
    user-select: none;

    &::after { // Element so that the flyout shadow isn't over the menu
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(-60deg, $blue, $blue 30%, $dark-blue 70%, $dark-blue);
        z-index: -1;
    }
}

.composer-scene-toolbar__item {
    position: relative;

    &:first-child,
    &:last-child {
        position: absolute;
        left: 0;
        right: 0;
    }

    &:first-child {
        top: 0;
        border-bottom: 1px solid $white-transparent-3;
    }

    &:last-child {
        bottom: 0;
        border-top: 1px solid $white-transparent-3;
    }


    &.composer-scene-toolbar__item--inactive {
        .composer-scene-toolbar__button {
            color: $white-transparent-3;
            cursor: not-allowed;
        }

        .composer-scene-toolbar__item-flyout {
            display: none;
        }
    }

    &:not(.composer-scene-toolbar__item--inactive) {
        .composer-scene-toolbar__button {
            &--add {
                background-color: $light-blue;

                &:hover {
                    background-color: lighten($light-blue, 3%) !important;
                }
            }
            &:hover {
                background-color: rgba(255,255,255,0.1);
            }
        }

        .composer-scene-toolbar__button:active,
        &.composer-scene-toolbar__item--activate .composer-scene-toolbar__button {
            background: $white;
            color: $primary-color;
        }

        &.composer-scene-toolbar__item--activate .composer-scene-toolbar__item-flyout {
            display: block;
        }
    }
}

.composer-scene-toolbar__item-flyout {
    background-color: $white;
    position: absolute;
    left: 100%;
    top: 0;
    padding: 0 10px;
    display: none;
    white-space: nowrap;

    &.composer-scene-toolbar__item-flyout--expand-up {
        top: auto;
        bottom: 0;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -2;
        @extend %box-shadow-far;
    }

    .slider {
        margin: 30px 0;
        width: 80px;
    }
}

.composer-scene-toolbar__button {
    &.composer-scene-toolbar__button--showing-flyout {
        @extend %box-shadow-far;
    }
    @include clear-outline;
    text-align: center;
    color: $white;
    padding: 10px 0;
    height: 100%;
    width: 100%;
    background: transparent;
    border: 0;
    position: relative;
}

.composer-scene-toolbar__button-icon {
    font-size: 30px;
    margin-bottom: 5px;
    display: block;
}
