@import "composer/header";
@import "composer/scene-toolbar";
@import "composer/object-toolbar";
@import "composer/image-uploader";

.composer {
    position: relative;
    padding-left: 80px;
    height: 768px;
}

.composer__wizard {
    position: absolute;
    top: 0;
    left: 80px;
    right: 0;
    background-color: white;
    padding: 0 60px;
    bottom: 0;
    display: none;
}

.composer__aside {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 80px;
}

.composer__image-uploader {
	padding-top: 50px;
}

.composer__composition,
.composer__lightset-wizard {
    height: 100%;
}

.composer__canvas-wrapper {
    position: relative;
}

.composer__main {
    padding: 0 60px;
    position: relative;
}

.composer__notification {
    position: absolute;
    bottom: 0;
    left: 80px;
    right: 0;
}


.composer__top,
.composer__image-uploader,
.composer__composition{
    width: 100%;
    @include align-contents(center,center);
}

.composer__canvas-top,
.composer__canvas-right {
    position: absolute;
    z-index: 1;

    .slider {
        background: white;
        padding: 20px;
        @extend %box-shadow-far;
    }
}

.composer__canvas-top {
    left: 50%;
    top: 50px;
    transform: translateX(-50%);

}

.composer__canvas-right {
    left: calc(50% + 320px);
    top: 50%;
    transform: translateY(-50%);
}

.composer__object-toolbar {
    position: absolute;
    left: 53.3%;
    bottom: 30px;
    transform: translateX(-50%);
}
