html, body {
    height: 100%;
}

.container {
    max-width: 1024px;
    margin: auto;
}

.container--inner {
    max-width: 768px;
}

.main {
    min-height: $main-height;
    display: none;
	position: relative;
}

.modal-overlay {
    background-color: rgba($black, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    min-height: 860px;
    z-index: 99;
	display: none;
}

.mobile-overlay {
    background-color: $primary-color;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    @include align-contents(center, center);

}
@media screen and (min-width: 1000px) and (min-height: 600px) {
    .mobile-overlay {
        display: none;
    }

    .main {
        display: block;
    }
}    

.mobile-overlay__text {
    font-size: 26px;
    line-height: 1.2;
    color: white;
    font-family: $font-family-bold;
}
