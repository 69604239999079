.image-uploader {
    height: 560px;
    width: 100%;
    @include align-contents(center, center);
    flex-direction: column;
    text-align: center;
    border: 2px dashed $very-light-blue;
    transition: color 0.2s, background-color 0.2s;

    &.image-uploader--over {
        background-color: $very-light-blue;
        border: white;
        color: white;

        .image-uploader__actions {
            opacity: 0;
        }
    }
}

.image-uploader__hidden-input {
    @include hide-input;
}

.image-uploader__add-image .icon-add {
    margin-right: 5px;
}

.image-uploader__inner {
    pointer-events: none;
}

.image-uploader__icon {
    font-size: 10rem;
    display: block;
    margin-bottom: 40px;
	color: $light-blue;
}

.image-uploader__actions {
    transition: opacity 0.2s;
    margin-top: 20px;
}
