.intro {
    padding: 50px 100px;
}

.intro__wrapper {
    display: flex;
}

.intro__wrapper img {
	width: 283px;
    height: 283px;
    display: block;
}

.intro__text {
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    width: 400px;
}

.intro__title {
    margin-top: 10px;
}

.intro__gui_wrapper {
    margin-top: 20px;
}
